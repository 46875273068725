import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Navbar from './components/Navbar'
import Tickets from './pages/Tickets'

const AuthenticatedApp = () => (
  <>
    <Navbar />
    <Routes>
      <Route path="/tickets" element={<Tickets />} />
    </Routes>
  </>
)

export default AuthenticatedApp
