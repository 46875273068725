import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Login from './pages/Login'

const UnAuthenticatedApp = () => (
  <Routes>
    <Route index element={<Login />} />
  </Routes>
)

export default UnAuthenticatedApp