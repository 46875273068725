import axios from 'axios'
import React, { useState } from 'react'
import groupBy from 'lodash/groupBy'
import orderBy from 'lodash/orderBy'
import partition from 'lodash/partition'
import { CalendarIcon } from '@heroicons/react/solid'
import moment from 'moment-timezone'
import { startOfDay } from 'date-fns'
import isAfter from 'date-fns/isAfter'
import useAccessTokenProvider from '../providers/AccessTokenProvider'

const vivenuApi = process.env.REACT_APP_VIVENU_API_ENDPOINT
const vivenuUrl = process.env.REACT_APP_VIVENU_URL

interface Event {
   id: string
   title: string
   startAt: string
   endAt: string
   timeZone: string
   type: string
}

interface SeatingInfo {
   _id: string
   sectionName: string
   groupName: string
   rowName: string
   seatName: string
   gate: string
}

interface Ticket {
   _id: string
   name: string
   amount: number
   price: number
   seatingInfo?: SeatingInfo
}

interface Transaction {
   tid: string
   secret: string
   sellerId: string
   tickets: Ticket[]
   email: string
   prename: string
   lastname: string
   street: string
   postal: string
   city: string
   country: string
   eventId: string
}

const Tickets = () => {
   const [token] = useAccessTokenProvider()
   const [events, setEvents] = useState<Event[]>([])
   const [transactions, setTransactions] = useState<Transaction[]>([])

   const onClickNavigateToDownloadTickets = (eventId: string, transactionId: string): React.MouseEventHandler<HTMLButtonElement> | undefined => (): void => {
      const transaction = transactions.find((transaction) => transaction.eventId === eventId && transaction.tid === transactionId)

      window.open(`${vivenuUrl}/transaction/${transaction!.tid}/${transaction!.secret}`, '_blank')
   }

   const renderSeatInfo = (seatingInfo: SeatingInfo) => {
      const { sectionName, rowName, seatName } = seatingInfo
      return `Vak ${sectionName} - Rij ${rowName} - Zitje ${seatName}`
   }

   const renderTickets = (eventId: string, transactionId: string) => {
      const tickets = transactions.filter((transaction: Transaction) => transaction.eventId === eventId && transaction.tid === transactionId).reduce((acc: Ticket[], transaction: Transaction) => [...acc, ...transaction.tickets], [])
      const orderedTickets = orderBy(tickets, ['seatingInfo.sectionName', 'seatingInfo.rowName'], ['asc', 'asc'])
      const [evenTicketSeats, unevenTicketSeats] = partition(orderedTickets, (ticket: Ticket) => parseInt(ticket.seatingInfo!.seatName) % 2 === 0)
      const orderedEventTickets = orderBy(evenTicketSeats, 'seatingInfo.seatName', 'desc')
      const orderedUnevenTickets = orderBy(unevenTicketSeats, 'seatingInfo.seatName', 'asc')
      
      return [...orderedUnevenTickets, ...orderedEventTickets].map((ticket) => (
         <li key={`ticket-${ticket._id}`}>
            <a className="block hover:bg-gray-50">
               <div className="px-4 py-4 sm:px-6">
                  <div className="flex items-center justify-between">
                     <div className="text-sm font-medium text-indigo-600 truncate">
                        {ticket.name}
                     </div>
                  </div>
                  <div className="mt-2 flex justify-between">
                     <div className="sm:flex">
                        <div className="flex items-center text-sm text-gray-500">
                           {ticket.seatingInfo != null && renderSeatInfo(ticket.seatingInfo)}
                        </div>
                     </div>
                  </div>
               </div>
            </a>
         </li>
      ))
   }

   const renderDate = (event: Event) => {
      const { startAt, timeZone } = event
      const momentDate = moment(startAt)
         .tz(timeZone)
         .locale('nl')
      const dayFull = momentDate.format('dddd')
      const date = momentDate.format('LL')
      const time = momentDate.format('HH:mm')
      const str = `${dayFull}, ${date} - ${time}`

      return (
         <div className="mt-2 flex">
            <div className="flex items-center text-sm text-gray-500">
            <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
            <p>
               {str}
            </p>
            </div>
         </div>
      )
   }

   const renderEvents = () => events.map((event: Event, index: number) => {
      const eventTransactions = transactions.filter((transaction) => transaction.eventId === event.id)
      return (
         <div key={`event-${event.id}`} className="mt-8 rounded bg-white shadow">
            <div>
               <div className="overflow-hidden">
                  <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                     <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                        <div className="ml-4 mt-2">
                           <h3 className="text-lg leading-6 font-medium text-gray-900">{event.title}</h3>
                           {renderDate(event)}
                        </div>
                     </div>
                  </div>
                  {
                     eventTransactions.map((transaction) => (
                        <>
                           <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                              <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                                 <div className="ml-4 mt-2">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">{`Transaction: ${transaction.tid}`}</h3>
                                 </div>
                                 <div className="ml-4 mt-2 flex-shrink-0">
                                    <button
                                       type="button"
                                       className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                       onClick={onClickNavigateToDownloadTickets(event.id, transaction.tid)}
                                    >
                                       <span>Download</span>                          
                                    </button>
                                 </div>
                              </div>
                           </div>
                           <ul role="list" className="divide-y divide-gray-200">
                              {renderTickets(event.id, transaction.tid)}
                           </ul>
                        </>
                     ))
                  }
               </div>
            </div>
         </div>
      )
   })
   
   /** Lifecycle methods */
   React.useEffect(() => {
      const fetchUpcomingEvents = async () => {
         try {
            const date = startOfDay(new Date())
            const transactionsResponse = await axios.get(`${vivenuApi}/customers/me/transactions`, { headers: { Authorization: `Bearer ${token}` }})
            const { events, transactions } = transactionsResponse.data
            const mappedEvents = events.map((event: any): Event => ({
               id: event._id,
               title: event.name,
               startAt: event.start,
               endAt: event.end,
               timeZone: event.timezone,
               type: event.eventType
            }))

            const mappedTransactions = transactions.map(({ tid, secret, sellerId, tickets, email, prename, lastname, street, postal, city, country, eventId }: any): Transaction => ({
               tid,
               secret,
               sellerId,
               tickets: tickets.map(({ _id, name, amount, price, seatingInfo }: any): Ticket => ({
                  _id,
                  name,
                  amount,
                  price,
                  ...(seatingInfo ? { seatingInfo: {
                     _id: seatingInfo._id,
                     sectionName: seatingInfo.sectionName,
                     groupName: seatingInfo.groupName,
                     rowName: seatingInfo.rowName,
                     seatName: seatingInfo.seatName,
                     gate: seatingInfo.gate
                  } } : {})
               })),
               email,
               prename,
               lastname,
               street,
               postal,
               city,
               country,
               eventId
            }))
            const orderedTransactions = orderBy(mappedTransactions, (transaction: Transaction) => {
               return mappedEvents.find((event: Event) => event.id === transaction.eventId).startAt
            }, 'asc')

            const filteredEvents = mappedEvents.filter((event: Event) => isAfter(new Date(event.endAt), date))
            const filteredTransactions = orderedTransactions.filter((transaction: any) => filteredEvents.some((event: any) => event.id === transaction.eventId))

            setEvents(filteredEvents)
            setTransactions(filteredTransactions)
         } catch (error) {
            console.error('Something went wrong')
         }
      }
      
      if (events.length === 0) {
         fetchUpcomingEvents()
      }
   }, [])

   return (
      <>
         <div style={{ height: '800px', top: '-470px', position: 'absolute', width: '100%', overflowX: 'hidden', zIndex: '-1', backgroundColor: '#FBC000', transform: 'skewY(-6deg)'}}>
         </div>
         <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 pb-8">
            {
               transactions.length !== 0 && renderEvents()
            }
         </div>
      </>
   )
}

export default Tickets
