import React from 'react'
import { useAuth } from './context/AuthContext'
import AuthenticatedApp from './AuthenticatedApp'
import UnauthenticatedApp from './UnauthenticatedApp'

const App = () => {
  // @ts-ignore
  const { customer } = useAuth()

  return (
    customer != null ? <AuthenticatedApp /> : <UnauthenticatedApp />
  )
}

export default App
