import axios from 'axios'
import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useAccessTokenProvider from '../providers/AccessTokenProvider'

const sellerId = process.env.REACT_APP_SELLER_ID
const vivenuApi = process.env.REACT_APP_VIVENU_API_ENDPOINT

type Customer = {
  id: string,
  firstName: string,
  lastName: string,
  email: string
}

// @ts-ignore
const AuthContext = React.createContext()
AuthContext.displayName = 'AuthContext'

const AuthProvider = (props: any) => {
  const navigate = useNavigate()
  const [token, setToken, removeToken] = useAccessTokenProvider()

  /* State */
  const [customer, setCustomer] = useState<Customer | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  const login = async (email: string, password: string) => {
    // Perform login
    try {
      const loginResponse = await axios.post(`${vivenuApi}/customers/login`, { email, password, sellerId })
      setToken(loginResponse.data.token)

      const meResponse = await axios.get(`${vivenuApi}/customers/me`, { headers: { Authorization: `Bearer ${loginResponse.data.token}` }})
      const { _id, prename, lastname, primaryEmail } = meResponse.data
      setCustomer({ id: _id, firstName: prename, lastName: lastname, email: primaryEmail })
    } catch (error: any) {
      if (error.response.status === 401) {
        throw error
      }
    }

    // Fetch the customer
    // {
    //   "_id": "64371dc91fe2aebaae867f93",
    //   "transactions": [],
    //   "tags": [],
    //   "verified": true,
    //   "primaryEmail": "vandenabeele.joachim+dev@gmail.com",
    //   "sellerId": "614da4d0bbb9591811275b42",
    //   "prename": "Joachim",
    //   "lastname": "Van den Abeele",
    //   "name": "Joachim Van den Abeele",
    //   "createdAt": "2023-04-12T21:08:25.686Z",
    //   "updatedAt": "2023-04-12T21:21:55.507Z",
    //   "__v": 0,
    //   "_account": {},
    //   "location": {
    //       "street": "Speytebeke 10",
    //       "postal": "9690",
    //       "city": "Ruien",
    //       "country": "BE"
    //   },
    //   "extraFields": {}
    // }
  }

  const logout = async () => {
    removeToken()
    setCustomer(null)
    navigate('/', { replace: true })
  }

  /** Lifecycle methods */
  React.useEffect(() => {
    const fetchCustomer = async () => {
      try {        
        const { data: data2 } = await axios.get(`${vivenuApi}/customers/me`, { headers: { Authorization: `Bearer ${token}` }})
        setCustomer({ id: data2._id, firstName: data2.prename, lastName: data2.lastname, email: data2.primaryEmail })
        navigate('/tickets', { replace: true })
      } catch (error) {
        // Unauthorized: token expired
        logout()
      }
    }

    const rehydrate = async () => {
      if (!token) {
        setIsLoading(false)
      } else {
        await fetchCustomer()
        setIsLoading(false)
      }
    }

    rehydrate()
  }, [])

  const value = React.useMemo(
    () => ({ customer, login, logout }),
    [customer, login, logout]
  )

  if (isLoading) {
    return <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8 text-center">Loading</div>
  }

  return <AuthContext.Provider value={value} {...props} />
}

const useAuth = () => {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }
  return context
}

export { AuthProvider, useAuth }