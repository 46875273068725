import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { AuthProvider } from '../context/AuthContext'

const AppProviders = ({ children }: { children: React.ReactChild | React.ReactChild[] }) => (
  <BrowserRouter>
    <AuthProvider>
      {children}
    </AuthProvider>
  </BrowserRouter>
)

export { AppProviders }