import React from 'react'

interface PasswordProps {
  passwordState: { value: string, errorMessage: string }
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const getClassNames = (error: boolean): string => 
  error 
    ? 'block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md' 
    : 'appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'

const Password = ({ passwordState, onChange }: PasswordProps) => {
  const { value: password, errorMessage } = passwordState
  const error = errorMessage !== ''
  return (
    <div>
      <label htmlFor="password" className="block text-sm font-medium text-gray-700">
        Wachtwoord
      </label>
      <div className="mt-1">
        <input
          id="password"
          name="password"
          type="password"
          autoComplete="current-password"
          className={getClassNames(error)}
          value={password}
          aria-invalid={error}
          aria-describedby="password-error"
          onChange={onChange}
        />
      </div>
      {error && (
        <p className="mt-2 text-sm text-red-600" id="password-error">
          {errorMessage}
        </p>
      )}
    </div>
  )
}

export default Password